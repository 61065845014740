@import '~scss/variables';
@import '~scss/mixins';

nav {
  @include themify('background', 'sidebar-background');
}

.main-background {
  @include themify('background', 'background-color');
}

.card-background {
  @include themify('background', 'card-background');
}

.input-background {
  @include themify('background', 'input-background');
}

.modal-content,
.modal-background {
  @include themify('background', 'modal-background');
}

.thumbnail-background {
  @include themify('background', 'thumbnail-background');
}

.hover-background {
  @include themify('background', 'hover-background');
}

.bg-hover:hover {
  @include themify('background', 'hover-background');
}

.badge-background {
  @include themify('background', 'badge-background');
}

.primary-background,
.progress-bar {
  @include themify('background', 'primary');
}

.error-background {
  background: rgba($red, 0.08);
  // background: #fee7e4;
}

// .card-hover:hover {
//   transform: scale(1.03);
// }

.error-background {
  @include themify('background', 'error-background');
}

.warning-background {
  @include themify('background', 'warning-background');
  .dark & {
    color: $yellow;
  }
  .light & {
    color: rgba(102, 77, 3, 0.8);
  }
}

.progress {
  .dark & {
    background: $white-12;
  }
  .light & {
    background: $black-12;
  }
}

.staging-badge {
  @include themify('color', 'on-surface-medium');
  @include themify('background', 'border-color');
}

.nav-active {
  .light & {
    background: rgba(1, 162, 153, 0.08);
  }

  .dark & {
    background: rgba(41, 67, 64, 1);
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  @include themify('background', 'primary');
  @include themify('color', 'on-primary-high');
}

.overlay-background {
  @include themify('background', 'on-primary-medium');
}

.dark-overlay {
  background: $black;
}

.light-overlay {
  background: $white;
}

thead,
.table-title {
  @include themify('background', 'tableHead-background');
}

tbody,
.tableBody-background {
  @include themify('background', 'tableBody-background');
}

.history-table, 
.video-table {
  & tbody,
  & thead {
    background: transparent;
  }
  & thead {
    @include border-style(bottom, 1px);
    @include themify('border-bottom-color', 'border-color');
  }
}

.table-hover > tbody > tr:hover > *,
.table-hover > tbody > th:hover > * {
  @include themify('color', 'on-surface-high');
  @include themify('background', 'hover-background');
}

.list-group-item-dark.list-group-item-action,
.list-group-item-dark.list-group-item-action {
  .dark & {
    background: $white-8;
  }
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  .dark & {
    background: $white-12;
  }
}

.dp-01 {
  @include themify('box-shadow', 'elevation-01');
}

.dp-02 {
  @include themify('box-shadow', 'elevation-02');
}