@import '~scss/variables';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  height: $header-height;
  padding: 0 $main-padding;
}

header > div,
.logo-icon {
  height: $thumbnail-height;
}

.main-container {
  padding: 0 $main-padding;
}

.live-container,
.live-container-scroll {
  height: calc(100vh - 4rem);
  scroll-behavior: smooth;
}

.live-container-scroll {
  overflow-x: auto;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.live-container-scroll::-webkit-scrollbar {
  display: none;
}

.sidebar-fold {
  width: $sidebar-width;
}

.sidebar-unfold {
  width: $sidebar-width-unfold;
}

$radius: calc(0.25rem - 1px);

.form-dialog {
  max-height: calc(100vh - 1rem);
  min-height: 50%;
  transform: translateY(-3.5rem);

  & .card {
    border-radius: 0px;

    &:first-of-type {
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;
    }

    &:last-of-type {
      border-bottom-right-radius: $radius;
      border-bottom-left-radius: $radius;
    }
  }

  .dark & .card-body {
    background: rgba($black, 0.2);
  }
}

.form-scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}

.history-table th:first-of-type,
.history-table td:first-of-type {
  padding-left: 1.5rem;
}

.table-title {
  height: 3.5rem;
}

.video-table {
  thead tr {
    height: auto;
  }
  tbody tr {
    height: 3rem;
  }
}

.source-table {
  tbody tr {
    height: auto;
    td {
      user-select: all;
    }
  }
}

.live2vod-table {
  tbody tr {
    font-size: 13px;
    height: auto;
    
    td {
      user-select: all;
    }
  }
}

thead tr,
tbody tr,
.nav-icon-size {
  width: 3rem;
  height: 3rem;
}

.icon-size {
  width: 1.5rem;
  height: 1.5rem;
}

.arrow {
  height: 1rem;
  width: 1rem;
  transform: translate(-300%, -50%) rotate(45deg);
}

.thumbnail-container {
  width: $thumbnail-height;
  height: $thumbnail-height;
}

.icon-column-width {
  width: 4.5rem;
}

.input-height {
  min-height: 6.5rem;
}

.badge-size {
  width: 1.75rem;
  height: 1.75rem;
}

.badge-padding {
  padding: 3px 6.5px;
}

.main-image {
  background-color: #3e7680;
  object-fit: cover;
}

.pe-pointer {
  cursor: pointer;
}

.alert-dismissible .btn-close {
  padding: 0px 0.75rem;
  height: 100%;
}

.p-25 {
  padding: 0.75rem;
}

.link {
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.title-height {
  line-height: $thumbnail-height;
}

.carousel-hide-icons {
  .carousel-control-prev,
  .carousel-control-next,
  .carousel-indicators {
    opacity: 0;
    pointer-events: none;
  }
}

.carousel-disable-icons {
  .carousel-control-prev,
  .carousel-control-next,
  .carousel-indicators {
    opacity: 0.15;
    pointer-events: none;
  }
}

.carousel-body {
  height: $snapshot-height;
}

.carousel-body {
  background: $black-grafana;
}

.carousel-loader > div {
  color: #03dac5 !important;
}

#force-single-tooltip .tooltip-inner {
  text-align: left;
  max-width: 80vw;
}

/**
 * Grafana style
 */
.bg-grafana {
  .light & {
    background: $white;
  }

  .dark & {
    background: $black-grafana;
  }
}

.bg-unknown {
  background: rgba(107, 107, 107, 0.38);
}

.bg-connected {
  background: rgb(255, 152, 48);
}

/**
 * z-index
 */

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-5 {
  z-index: 5;
}

.z-10 {
  z-index: 10;
}

@include media-breakpoint-up(lg) {
  .config-code-height pre {
    max-height: 45vh;
  }

  .live-container-scroll {
    overflow-x: hidden;
  }
}

@include media-breakpoint-up(xxl) {
  header,
  .main-container {
    padding: 0 5%;
  }
}

@include media-breakpoint-up(xxxl) {
  header,
  .main-container {
    padding: 0 15%;
  }

  .sidebar-unfold {
    width: 18rem;
  }
}

@include media-breakpoint-up(xxxxl) {
  header,
  .main-container {
    padding: 0 20%;
  }

  .sidebar-unfold {
    width: 20rem;
  }
}
