.transition {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.btn-transition {
  transition: color 0.15s ease-in-out;
}

.bg-transition {
  transition: background-color 0.15s ease-in-out;
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out 0s both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.pop-up {
  animation: popUp 0.15s linear 0.2s both;
}

@keyframes popUp {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  80% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.lazy-load {
  animation: blurIn 0.4s both;
}

@keyframes blurIn {
  from {
    opacity: 0;
    filter: blur(3px);
  }
  to {
    opacity: 1;
    filter: none;
  }
}

.sort-transition {
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

.asc-button {
  transform: rotate(0deg);
}

.desc-button {
  transform: rotate(180deg);
}
