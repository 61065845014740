@import '~bootstrap/scss/mixins';
@import '~scss/functions';
@import '~scss/mixins';
@import '~scss/variables';

/**
 * Primary Button
 */
.btn-primary {
  .light & {
    @include button-variant(set-primary-color(light), set-primary-color(light));
  }

  .dark & {
    @include button-variant(set-primary-color(dark), set-primary-color(dark));
  }

}

.btn-primary,
.btn-primary:active,
.btn-primary:focus {
  @include themify('color', 'on-primary-high');
}

.btn-primary:disabled {
  @include themify('color', 'on-primary-high');
  @include themify('background', 'border-color');
}

/**
 * Primary Outline Button
 */
.btn-outline-primary {
  .light & {
    @include button-outline-variant(set-primary-color(light));
  }

  .dark & {
    @include button-outline-variant(set-primary-color(dark));
  }
}

.btn-outline-primary:disabled {
  @include themify('color', 'border-color');
}

/**
 * Primary & Primary Outline Button
 */
.btn-primary:hover,
.btn-outline-primary:hover {
  @include themify('color', 'on-primary-high');
}

.btn-primary:disabled,
.btn-outline-primary:disabled {
  @include themify('border-color', 'border-color');
}

/**
 * Empty Button
 */
.btn-empty:hover {
  @include themify('color', 'on-surface-medium');
}

.btn-action:hover {
  opacity: 0.6;
}

.btn-action:disabled {
  opacity: 0.2;
}

.btn-outline-dark {
  border-color: rgba(0, 0, 0, 0.2);
}

.btn-outline-light {
  border-color: rgba(255, 255, 255, 0.2);
}
