$font-path: 'https://cockpit.tiled.media/fonts';

/**
 * Uni Neue
 */
@font-face {
  font-family: 'UniNeue';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url($font-path + '/UniNeue/UniNeue-Regular-400.otf') format('opentype');
}

@font-face {
  font-family: 'UniNeue';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url($font-path + '/UniNeue/UniNeue-Bold-700.otf') format('opentype');
}

/**
 * Chivo
 */
@font-face {
  font-family: 'Chivo';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url($font-path + '/Chivo/Chivo-Light-300.ttf') format('truetype');
}

@font-face {
  font-family: 'Chivo';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url($font-path + '/Chivo/Chivo-Regular-400.ttf') format('truetype');
}

@font-face {
  font-family: 'Chivo';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url($font-path + '/Chivo/Chivo-Bold-700.ttf') format('truetype');
}

label,
th,
.state-tabs {
  font-family: 'UniNeue', sans-serif;
}

.base-font {
  font-family: 'Chivo', sans-serif;
}

.text-md {
  font-size: 1.125rem;
}

.text-sm {
  font-size: 0.9375rem !important;
}

.text-xs {
  font-size: 0.75rem !important;
}
