@import '~scss/variables';
@import '~scss/mixins';
@import '~scss/functions';

.form-control,
.form-select {
  // @include themify('border-color', 'border-color');
  @include themify('color', 'on-surface-high');
}

.form-select:disabled,
.form-control:read-only:not([type='file']) {
  @include themify('background', 'border-color');
  @include themify('color', 'on-surface-disabled');
  @include themify('border-color', 'border-color');
}

.form-control::placeholder {
  @include themify('color', 'on-surface-disabled');
  font-size: 0.875rem;
}

.form-control::-ms-input-placeholder {
  @include themify('color', 'on-surface-disabled');
  font-size: 0.875rem;
}

option {
  color: initial;
}

select,
input:not([type='checkbox']),
textarea {
  @include themify('background-color', 'input-background');
}

.input-md {
  width: 9rem;
}

select:focus,
input:focus:not([type='checkbox']),
textarea:focus {
  @include themify('background-color', 'input-background');
  @include themify('box-shadow', 'focus-shadow');
  border-color: #80d1cc;
}

.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgb(249 55 28 / 25%) !important;
}

input:-internal-autofill-selected,
.form-control:disabled,
.form-control:read-only,
.form-control:focus {
  @include themify('background-color', 'input-background');
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  .dark & {
    filter: invert(68%) sepia(60%) saturate(784%) hue-rotate(119deg) brightness(91%) contrast(98%);
  }
  .light & {
    filter: invert(46%) sepia(56%) saturate(3787%) hue-rotate(148deg) brightness(92%) contrast(99%);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  .dark & {
    -webkit-box-shadow: 0 0 0 50px #484848 inset !important;
    box-shadow: 0 0 0 50px #484848 inset !important;
    -webkit-text-fill-color: $white-87;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

.dark .unit-input {
  opacity: 0.6;
}

.dark .form-select:not(:disabled) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23eeeeee' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
}

.form-select:disabled {
  background-image: none !important;
}

.search-input:focus-within {
  @include themify('border-color', 'primary');
}

.search-input button:hover {
  & svg {
    opacity: 0.6;
  }
}

.form-check-input {
  .dark & {
    background-color: $white-8;
  }
}

.form-check-input:checked,
.form-check-input:checked[type='checkbox'] {
  .dark & {
    background-color: $teal-200;
    border-color: $teal-200;
  }
}

.form-check-input:checked[type='checkbox'] {
  .dark & {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$black-60}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
  }
}

.no-checked-icon {
  background-image: none !important;
}

#dropdown-outputs,
#dropdown-slates {
  // min-width: 240px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#dropdown-outputs,
#dropdown-outputs:hover,
#dropdown-outputs:focus,
#dropdown-slates,
#dropdown-slates:hover,
#dropdown-slates:focus {
  @include themify('border-color', 'border-color');
  @include themify('background-color', 'input-background');
}

.dropdown-menu .dropdown-item.disabled,
.dropdown-menu .dropdown-item:disabled,
.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  .dark & {
    opacity: 0.38;
  }
}

.dropdown-menu,
.dropdown-menu-dark {
  @include themify('border-color', 'border-color');
  @include themify('background-color', 'modal-background');
}

.dropdown-item:hover,
.dropdown-toggle,
.dropdown-toggle:active,
.dropdown-toggle:focus {
  @include themify('color', 'on-surface-high');
}

.dropdown-toggle:hover,
.dropdown-toggle::after {
  @include themify('color', 'on-surface-medium');
}

.dropdown-toggle::after {
  margin-left: 1.5rem;
}

.no-menu .dropdown-toggle::after {
  display: none !important;
}

.action-dropdown,
.profile-dropdown {
  .dropdown-toggle {
    background: transparent !important;
    box-shadow: none !important;
    border: none;
  }
}

.action-dropdown {
  .dropdown-menu {
    padding: 0.5rem;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.25rem;
  }

  .dropdown-item:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    .dark & {
      background: $white-12;
    }
    .light & {
      background: $black-12;
    }
  }

  button::after {
    display: none;
  }

  a:hover {
    svg {
      @include themify('fill', 'on-surface-high');
    }
  }

  .danger {
    &:hover {
      svg {
        fill: $red !important;
      }
      @include themify('background', 'error-background');
    }
  }
}

.action-dropdown hr,
.profile-dropdown hr {
  width: calc(100% + 1rem);
  transform: translateX(-0.5rem);
  margin: 0.5rem 0px;
}
