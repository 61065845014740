/**
 * Primary
 */
$teal-500: #01a299;
$teal-200: #03dac5;

/**
 * Accent
 */
$red: #f9371c;
$blue: #2cadf6;
$yellow: #f9c81c;

/**
 * Text
 */
$white: #ffffff;
$white-100: rgba($white, 1);
$white-87: rgba($white, 0.87);
$white-74: rgba($white, 0.74);
$white-60: rgba($white, 0.6);
$white-38: rgba($white, 0.38);
$white-12: rgba($white, 0.12);
$white-8: rgba($white, 0.08);

$black: #000000;
$black-100: rgba($black, 1);
$black-87: rgba($black, 0.87);
$black-74: rgba($black, 0.74);
$black-60: rgba($black, 0.6);
$black-38: rgba($black, 0.38);
$black-12: rgba($black, 0.12);
$black-8: rgba($black, 0.08);

$black-grafana: #191b1f;

/**
 * Shadow
 */
$elevation-01dp-light: 0px 1px 4px rgba(52, 60, 68, 0.1);
$elevation-02dp-light: 0px 10px 20px rgba($black, 0.04),
  0px 2px 6px rgba($black, 0.04), 0px 0px 1px rgba($black, 0.04);

$elevation-01dp-dark: 0px 1px 1px rgba($black, 0.14),
  0px 2px 1px rgba($black, 0.12), 0px 1px 3px rgba($black, 0.2);
$elevation-02dp-dark: 0px 2px 2px rgba($black, 0.14),
  0px 3px 1px rgba($black, 0.12), 0px 1px 5px rgba($black, 0.2);

/**
 * Size
 */
$main-padding: 2rem;
$header-height: 4rem;
$sidebar-width: 4rem;
$sidebar-width-unfold: 16.5rem;
$thumbnail-height: 2.5rem;

$snapshot-height: 296px;
