@import '~scss/variables';
@import '~scss/mixins';

/**
 * Color
 */
.color-primary,
.hover-primary,
.state-tabs .active {
  @include themify('color', 'primary');
}

.hover-primary:hover {
  .light & {
    color: #01827a;
  }
  .dark & {
    color: #29e0ce;
  }
}

.state-tabs .active {
  border-bottom: 2px solid transparent;
  @include themify('border-color', 'primary');
}

.color-high {
  @include themify('color', 'on-surface-high');
}
.color-medium {
  @include themify('color', 'on-surface-medium');
}
.color-disabled,
.state-tabs a {
  @include themify('color', 'on-surface-disabled');
}

.color-primary-high {
  @include themify('color', 'on-primary-high');
}
.color-primary-medium {
  @include themify('color', 'on-primary-medium');
}

.text-black {
  color: $black;
}

/**
 * Fill
 */
.fill-primary {
  @include themify('fill', 'primary');
}

.fill-high {
  @include themify('fill', 'on-surface-high');
}
.fill-medium {
  @include themify('fill', 'on-surface-medium');
}
.fill-disabled {
  @include themify('fill', 'on-surface-disabled');
}

/**
 * Broder
 */
.border-focus {
  @include border-style(all, 1px);
  @include themify('border-color', 'primary');
}

.border-line,
.border-high,
.border-medium,
.border-disabled {
  @include border-style(all, 1px);
}

hr {
  @include themify('background-color', 'on-surface-medium');
}

.border-line {
  @include themify('border-color', 'border-color');
}

.border-high {
  @include themify('border-color', 'on-surface-high');
}
.border-medium {
  @include themify('border-color', 'on-surface-medium');
}
.border-disabled {
  @include themify('border-color', 'on-surface-disabled');
}

.underline {
  @include border-style(bottom, 1px);
  @include themify('border-bottom-color', 'border-color');
}
