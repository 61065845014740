@mixin themify($key, $color) {
  @each $theme-name, $theme-color in $themes {
    .#{$theme-name} & {
      #{$key}: map-get(map-get($themes, $theme-name), $color);
    }
  }
}

@mixin border-style($direction, $width) {
  @if ($direction == all) {
    border-width: $width;
    border-style: solid;
  }
  border-#{$direction}-width: $width;
  border-#{$direction}-style: solid;
}
