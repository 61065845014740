@import '~scss/typography';
@import '~scss/variables';

$font-family-base: 'Chivo', sans-serif;
$btn-font-family: 'UniNeue', sans-serif;
$headings-font-family: 'UniNeue', sans-serif;
$headings-font-weight: 700;

$primary: $teal-500; // for light theme
$secondary: $teal-200; // for dark theme
$success: $teal-200;
$info: $blue;
$warning: $yellow;
$danger: $red;
$light: $white-60;
$dark: $black-60;

$btn-disabled-opacity: 0.5;
$btn-font-weight: 500;
$btn-font-family: 'Chivo', sans-serif;

$modal-content-border-width: 0;
$modal-backdrop-opacity: 0.6;
// $table-cell-padding-x: 1.5rem;
$table-hover-bg-factor: 0.05;

$progress-height: 6px;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/mixins';

$opacity-utilities: (
  'opacity': (
    property: opacity,
    values: (
      0: 0,
      38: 0.38,
    )
  ),
  "bg-opacity": (
    css-var: true,
    class: bg-opacity,
    values: (
      60: 0.6
    )
  ),
);

$utilities: map-merge($utilities, $opacity-utilities);

$new-grid-breakpoints: (
  xxxl: 1920px,
  xxxxl: 2560px
);

$grid-breakpoints: map-merge($grid-breakpoints, $new-grid-breakpoints);

@import '~bootstrap/scss/bootstrap.scss';
@import '~scss/global';
@import '~scss/mixins';
@import '~scss/functions';
@import '~scss/animations';
@import '~scss/themes';
@import '~scss/colors';
@import '~scss/backgrounds';
@import '~scss/buttons';
@import '~scss/inputs';
@import '~scss/toastify';
